<template>
  <div class="container">

    <!-- HEADER -->
    <div data-testid="progress-bar" class="header">
      <StageStepper v-if="step !== 5" :step="step"/>
      <StyledH1 v-if="step === 5">{{ $t('isakey.activationEndHeader') }}</StyledH1>
    </div>

    <!-- MAIN -->
    <div  class="main">
      <div v-if="step === 1" class="instructions">
        <div class="instructions-1">
          <ol>
            <li>{{ $t('isakey.activationStep1Action1') }}</li>
            <li>{{ $t('isakey.activationStep1Action2_1') }}&nbsp;<img src="@/assets/images/isakey-power-button.png" style="width:20px;height:20px;" />&nbsp; &nbsp;&nbsp;{{ $t('isakey.activationStep1Action2_2') }}</li>
            <li>{{ $t('isakey.activationStep1Action3') }}</li>
          </ol>
          <img src="@/assets/images/isakey.png" />
        </div>
      </div>
      <div v-if="step === 2" class="instructions">
        <ol>
          <li>{{ $t('isakey.activationStep2Action1') }}
            <div>
              <img data-testid="cronto-image" alt="Cronto" class="cronto" src="@/assets/images/DpShowSerialNumber_Isabel_8.png">
            </div>
          </li>
          <li>{{ $t('isakey.activationStep2Action2') }}</li>
        </ol>
        <imask-input
          @input="inputSerialNumber"
          v-focus
          ref='serial'
          v-model="serial"
          :mask="'00-0000000-0'"
          :lazy="false"
          :unmask="true"
        />
        <div
          v-if="validationError"
          class="validation-error"
        >
          {{ $t(validationError) }}
        </div>
      </div>
      <div v-if="step === 3 && !showConfirmation" class="instructions">
        <ol>
          <li>{{ $t('isakey.activationStep3Action1') }}
            <div>
              <img alt="Cronto" class="cronto" v-bind:src="'data:image/png;base64,' + challenge1">
            </div>
          </li>
          <li>{{ $t('isakey.activationStep3Action2') }}</li>
          <li>{{ $t('isakey.activationStep3Action3') }}</li>
        </ol>
        <imask-input
          ref='code1'
          v-model="code1"
          :mask="'000 000 000 000 000'"
          :lazy="false"
          :unmask="true"
        />
        <div
          v-if="validationError"
          class="validation-error"
        >
          {{ $t(validationError) }}
        </div>
      </div>
      <div v-if="step === 3 && showConfirmation" class="instructions">
        <div class="alert-success">{{ $t('isakey.activationStep3ConfirmationAlert') }}</div>
        <div class="instructions3b">
          <ol>
            <li v-for="confirmationStep in getStep3ConfirmationSteps()" :key="confirmationStep">{{ confirmationStep }}</li>
          </ol>
        </div>
      </div>
      <div v-if="step === 4" class="instructions">
        <ol>
          <li>{{ $t('isakey.activationStep4Action1') }}
            <div class="cronto-wrapper">
              <img alt="Cronto" class="cronto" v-bind:src="'data:image/png;base64,' + challenge2">
              <div>
                <i class="material-icons">question_mark</i>
                <div class="cronto-help">
                  <span>{{ $t('isakey.activationStep4Action2CrontoProblem') }}</span>
                  <a data-testid="step-4-go-back-link" href="" v-on:click="redoStep3()" v-on:click.prevent>{{ $t('isakey.activationStep4Action2RetryStep3') }}</a>
                </div>
              </div>
            </div>
          </li>
          <li>{{ $t('isakey.activationStep4Action2') }}</li>
        </ol>
        <imask-input
          ref='code2'
          v-model="code2"
          :mask="'0000 0000'"
          :lazy="false"
          :unmask="true"
        />
        <div
          v-if="validationError"
          class="validation-error"
        >
          {{ $t(validationError) }}
        </div>
      </div>
      <div v-if="step === 5" class="instructions">
        <p>{{ $t('isakey.activationEnd1') }}</p>
        <p>
        <img data-testid="isabel6-link" class="partner" v-on:click=logoutAndGoto(Partner.Isabel6) :src='"@/assets/images/Isabel6_Vertical_POS.svg"'>
        <img class="partner" v-on:click=logoutAndGoto(Partner.CBC) :src='"@/assets/images/logos-cbc--multicolor.svg"'>
        <img class="partner" v-on:click=logoutAndGoto(Partner.KBC) :src='"@/assets/images/logos-kbc--multicolor.svg"'>
        <img class="partner" v-on:click=logoutAndGoto(Partner.KBCB) :src='"@/assets/images/logos-kbc-brussels--multicolor.svg"'>
        </p>
        <p>
        <img class="partner" v-on:click=logoutAndGoto(Partner.ING) :src='"@/assets/images/ING_Primary_Logo_RGB.svg"'>
        <img class="partner" v-on:click=logoutAndGoto(Partner.Belfius) :src='"@/assets/images/Belfius_RT_Logo_H_RGB.svg"'>
        </p>
        <p class="attention">{{ $t('isakey.activationEnd3')[0] }}</p>
        <p class="attention-message">{{ $t('isakey.activationEnd3')[1] }}</p>
        <p>{{ $t('isakey.activationEnd3')[2] }}</p>
        <p>{{ $t('isakey.activationEnd3')[3] }} <a :href="$t('isakey.moreInfoLink')" target="_blank">{{ $t('isakey.moreInfo') }}</a></p>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="footer">
      <StyledButton
        data-testid="back-to-profile-button"
        v-if="step === 5"
        v-on:click="goToProfile()">
        {{ $t('isakey.backToProfile') }}
      </StyledButton>

      <StyledButton
        data-testid="next-button"
        :disabled=isButtonDisabled()
        :filled=true
        v-on:click="dispatch(step)"
        v-on:keyup.enter="dispatch(step)"
        ref="nextButton">
        {{ getSecondButtonLabel(step) }}
      </StyledButton>
    </div>

    <ModalWindow
      v-if="showModal"
      @cancel="cancelModal"
      @confirm="confirmModal"
      :title-text="$t('isakey.modal.reactivation.title')"
      :body-text="sameUserForReactivation ?
        $t('isakey.modal.reactivation.bodySameUser', [userFullNameForReactivation]) :
        $t('isakey.modal.reactivation.bodyOtherUser', [userFullNameForReactivation])"
      :steps="[$t('isakey.modal.reactivation.step1'), $t('isakey.modal.reactivation.step2')]"
      :cancel-text="$t('isakey.modal.reactivation.cancel')"
      :confirm-text="$t('isakey.modal.reactivation.confirm')"
    ></ModalWindow>

  </div>
</template>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
  }

  h1 {
    margin: 0px;
  }

  .footer {
    display: flex;
    margin-top: 2rem;
  }

  .footer button {
     flex: 1;
  }

  .footer button:nth-of-type(2) {
     margin-left: 0.5rem;
  }

  .main {
    display: flex;
    flex-direction: row;
    line-height: 28px;
    font-weight: 300;
    letter-spacing: 0.33px;
  }

  .instructions {
    flex-grow: 1;
  }

  .instructions-1 {
    display: flex;
    justify-content: space-between;
  }

  .instructions3b {
    margin-top: 2rem;
  }

  .instructions-1 img{
    width: 150px;
    height: 250px;
    margin-right: -8px;
  }

  .cronto-wrapper {
    display: flex;
    align-items: center;
  }

  .cronto-wrapper > div {
    display: flex;
  }

  .cronto-wrapper i {
    margin: 0 .5rem;
    color: #911448;
  }

  img.cronto {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
    margin-left: -12px;
  }

  .cronto-help {
    display: flex;
    flex-direction: column;
  }

  .cronto-help a {
    text-decoration: underline;
  }

  .alert-success {
    background-color: #69a64e;
    color: white;
    border: 0.1rem solid black;
    padding: 0.5rem 0;
    text-align: center;
  }

  ol {
    margin-block-start: 0;
    margin-block-end: 0;
    counter-reset: item;
    padding-left: 16px;
  }

  ol li {
    display: block;
    margin-bottom: .5rem;
  }

  ol li::before {
    display: inline-block;
    content: counter(item)".";
    counter-increment: item;
    font-size: 13px;
    font-weight: bold;
    width: 1.2em;
    margin-left: -1.2em;
  }

  .partner {
    height: 94px;
    width: 94px;
    cursor: pointer;
  }

  input {
    width: 17rem;
    padding: 5px;
    border: 1px solid rgb(200, 200, 200);
    margin-left: 16px;
  }

  .validation-error {
    color: red;
    text-align: left;
    font-size: small;
    font-weight: bolder;
    margin-left: 16px;
  }

  .attention {
    font-weight: bold;
  }

  .attention-message {
    color: red;
    font-weight: bold;
  }
</style>

<script>
import axiosApi from '@/services/AxiosService'
import UserManager from '@/services/SecurityService'
import Const from '@/services/Constants'

import StyledButton from '@/components/styled/StyledButton'
import StageStepper from '@/components/StageStepper'
import StyledH1 from '@/components/styled/StyledTitle'
import ModalWindow from "@/components/ModalWindow.vue";
import {IMaskComponent} from "vue-imask";

const Partner = Object.freeze({
  Isabel6: Symbol("Isabel 6"),
  KBC: Symbol("KBC"),
  KBCB: Symbol("KBC Brussels"),
  CBC: Symbol("CBC"),
  ING: Symbol("ING"),
  Belfius: Symbol("Belfius")
})

const partnerLinks =
    {
      en: {
        isabel6: process.env.VUE_APP_PARTNER_LINK_ISABEL6_EN ?? "https://www.isabel.eu/en.html",
        kbc: process.env.VUE_APP_PARTNER_LINK_KBC_EN ?? "https://www.kbc.be/business",
        kbcb: process.env.VUE_APP_PARTNER_LINK_KBCB_EN ?? "https://www.kbcbrussels.be/business",
        cbc: process.env.VUE_APP_PARTNER_LINK_CBC_EN ?? "https://www.cbc.be/entreprendre",
        ing: process.env.VUE_APP_PARTNER_LINK_ING_EN ?? "https://www.ing.be/en/business/payments/isakey",
        belfius: process.env.VUE_APP_PARTNER_LINK_BELFIUS_EN ?? "https://belfiusweb.belfius.be/isabel"
      },
      nl: {
        isabel6: process.env.VUE_APP_PARTNER_LINK_ISABEL6_NL ?? "https://www.isabel.eu/nl.html",
        kbc: process.env.VUE_APP_PARTNER_LINK_KBC_NL ?? "https://www.kbc.be/ondernemen",
        kbcb: process.env.VUE_APP_PARTNER_LINK_KBCB_NL ?? "https://www.kbcbrussels.be/ondernemen",
        cbc: process.env.VUE_APP_PARTNER_LINK_CBC_NL ?? "https://www.cbc.be/entreprendre",
        ing: process.env.VUE_APP_PARTNER_LINK_ING_NL ?? "https://www.ing.be/en/business/payments/isakey",
        belfius: process.env.VUE_APP_PARTNER_LINK_BELFIUS_NL ?? "https://belfiusweb.belfius.be/isabel"
      },
      fr: {
        isabel6: process.env.VUE_APP_PARTNER_LINK_ISABEL6_FR ?? "https://www.isabel.eu/fr.html",
        kbc: process.env.VUE_APP_PARTNER_LINK_KBC_FR ?? "https://www.kbc.be/entreprendre",
        kbcb: process.env.VUE_APP_PARTNER_LINK_KBCB_FR ?? "https://www.kbcbrussels.be/entreprendre",
        cbc: process.env.VUE_APP_PARTNER_LINK_CBC_FR ?? "https://www.cbc.be/entreprendre",
        ing: process.env.VUE_APP_PARTNER_LINK_ING_FR ?? "https://www.ing.be/en/business/payments/isakey",
        belfius: process.env.VUE_APP_PARTNER_LINK_BELFIUS_FR ?? "https://belfiusweb.belfius.be/isabel"
      }
    }

export default {
  components: {
    StyledButton,
    StyledH1,
    StageStepper,
    ModalWindow,
    'imask-input': IMaskComponent
  },
  data () {
    return {
      Partner,
      userManager: new UserManager(),
      isabelUserId: null,
      step: 1,
      serial: '',
      challenge1: null,
      challenge2: null,
      code1: '',
      code2: '',
      userFullNameForReactivation: null,
      sameUserForReactivation: false,
      showModal: false,
      validationError: null,
      requestPending: false,
      showConfirmation: false,
    }
  },
  created () {
    /* Set the event management when we click on enter. */
    const component = this
    this.keyHandler = function (event) {
      if (event.keyCode === 13) {
        component.triggerNext()
      }
    }
    window.addEventListener('keyup', this.keyHandler)
    window.addEventListener("beforeunload", this.handleBeforeUnload)

    this.userManager.getUserId().then((userId) => {
      this.isabelUserId = userId
      this.sendActivationStarted()
    })

    this.emitStepChangedEvent()
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.keyHandler)
    window.removeEventListener("beforeunload", this.handleBeforeUnload)
  },
  methods: {
    handleBeforeUnload(event) {
      if (this.step !== 5) {
        event.preventDefault()
      }
    },
    emitStepChangedEvent() {
      this.$emit('stepChanged', this.step);
      if(this.step === 3) {
        this.focusOnCode1InputDelayed()
      }
      if(this.step === 4) {
        this.focusOnCode2InputDelayed()
      }
    },
    redoStep3()
    {
      if(this.requestPending){
        return;
      }
      this.step = 2;
      this.sendSerialAndGetCronto(false)
      this.code1 = ''
      this.code2 = ''
      this.triggerNext()
    },
    dispatch (step) {
      if(this.requestPending || this.showModal || this.isButtonDisabled()){
        return;
      }
      if(this.step > step){
        this.step = step;
        return;
      }
      switch (step) {
        case 1:
          this.step++
          this.emitStepChangedEvent()
          break
        case 2:
          this.sendSerialAndGetCronto(false)
          break
        case 3:
          if (this.showConfirmation) {
            this.confirmStep3()
            break
          }
          this.sendCode1AndGetCronto()
          break
        case 4:
          this.sendCode2()
          break
        case 5:
          this.logoutAndGoto(Partner.Isabel6)
          break
      }
    },
    confirmStep3() {
      this.showConfirmation = false
      this.step++
      this.emitStepChangedEvent()
    },
    getStep3ConfirmationSteps() {
      return this.$t('isakey.activationStep3ConfirmationSteps');
    },
    getSecondButtonLabel (step) {
      if (step === 3 && this.showConfirmation) {
        return this.$t('isakey.activationStep3ConfirmationButtonText')
      }
      if (step === 4) {
        return this.$t('isakey.complete')
      }
      if (step === 5) {
        return this.$t('common.close')
      }
      return this.$t('common.next')
    },
    sendActivationStarted () {
      this.requestPending = true

      axiosApi
        .post(`/users/${this.isabelUserId}/tokens`, {"step": 0})
        .catch((error) => console.log(error))
        .finally(() => {
          this.requestPending = false
        })
    },
    sendSerialAndGetCronto (confirmReactivation) {
      this.requestPending = true

      const envelope = {}
      envelope.step = confirmReactivation ? 101 : 1
      envelope.serialNumber = this.serial

      axiosApi
        .post(`/users/${this.isabelUserId}/tokens`, envelope)
        .then((data) => {
          this.$emit('errorPath', null)
          if(data.data.challenge) {
            this.challenge1 = data.data.challenge
            this.userFullNameForReactivation = null
            this.step++
            this.emitStepChangedEvent()
          } else {
            this.userFullNameForReactivation = data.data.userFullNameForReactivation
            this.sameUserForReactivation = data.data.sameUserForReactivation
            this.showModal = true
          }
          this.requestPending = false
        })
        .catch((error) => {
          this.handleErrorCodes(error)
          this.focusOnSerialNumberInput()
          this.requestPending = false
        })
    },
    sendCode1AndGetCronto () {
      this.requestPending = true

      const envelope = {}
      envelope.step = 2
      envelope.response = this.code1

      axiosApi
        .post(`/users/${this.isabelUserId}/tokens`, envelope)
        .then((data) => {
          this.$emit('errorPath', null)
          this.challenge2 = data.data.challenge
          this.requestPending = false
          this.showConfirmation = true;
        })
        .catch((error) => {
          this.handleErrorCodes(error)
          this.focusOnCode1Input()
          this.requestPending = false
        })
    },
    sendCode2 () {
      this.requestPending = true

      const envelope = {}
      envelope.step = 3
      envelope.response = this.code2

      axiosApi
        .post(`/users/${this.isabelUserId}/tokens`, envelope)
        .then(() => {
          this.$emit('errorPath', null)
          this.step++
          this.emitStepChangedEvent()
          this.requestPending = false
        })
        .catch((error) => {
          this.handleErrorCodes(error)
          this.focusOnCode2Input()
          this.requestPending = false
        })
    },
    handleErrorCodes (error) {
      const paths = {
        INVALID_SERIAL: 'invalidSerial',
        INVALID_TOKEN_STATE: 'invalidState',
        NO_ONGOING_ACTIVATION: 'noOngoingActivation',
        MULTIPLE_ONGOING_ACTIVATIONS: 'multipleOngoingActivations',
        INVALID_ISAKEY_RESPONSE: 'invalidIsakeyResponse'
      }

      if (error?.response?.status === 401) {
        this.$emit('errorPath', 'isakey.errors.invalidAccessToken')
      }
      else {
        this.$emit('errorPath', 'isakey.errors.' + (paths[error?.response?.data?.errors?.[0]?.title] ?? 'technical'))
      }
    },
    triggerNext () {
      this.$refs.nextButton.$listeners.click()
    },
    goToProfile () {
      this.$router.push(Const.router.profile)
    },
    logoutAndGoto(partner) {
      let url, lang = this.$i18n.locale ?? "en"
        switch (partner) {
          case Partner.KBC:
            url = partnerLinks[lang]['kbc']
            break;
          case Partner.CBC:
            url = partnerLinks[lang]['cbc']
            break;
          case Partner.KBCB:
            url = partnerLinks[lang]['kbcb']
            break;
          case Partner.ING:
            url = partnerLinks[lang]['ing']
            break;
          case Partner.Belfius:
            url = partnerLinks[lang]['belfius']
            break;
          case Partner.Isabel6:
          default:
            url = partnerLinks[lang]['isabel6']
            break;
        }
      this.userManager.signOut(url)
    },
    isInputValid (input, step) {
      if (!input) {
        return false
      }

      const regex =   step === 2 ? /^[0-9]{10}$/
                    : step === 3 ? /^[0-9]{15}$/
                    : /^[0-9]{8}$/
                    ;

      if(!regex.test(input)) {
        return false
      }
      //modulo to check validity of input serialNumber
      if (step === 2 && !this.isSerialNumberValid(input)) {
        this.validationError = "isakey.validation.responseInvalidSN"
        return false
      }

      //if here, all is fine with validation
      const validatedInput = input
      this.validationError = null
      return validatedInput
    },
    focusOnCode1InputDelayed() {
      this.$nextTick(() => {
        this.focusOnCode1Input()
      })
    },
    focusOnCode2InputDelayed() {
      this.$nextTick(() => {
        this.focusOnCode2Input()
      })
    },
    cancelModal() {
      this.showModal = false
    },
    confirmModal() {
      this.showModal = false
      this.sendSerialAndGetCronto(true)
    },
    isSerialNumberValid(serialNumber) {
      //  Modulo check:
      //  Given x = 3 * (sum of numbers in even positions)
      //  And y = sum of numbers in odd positions
      //  Then modulo 10 of x + y should be 0
      let sum = 0;
      for (let i = 0; i < serialNumber.length; i += 1) {
        const digit = parseInt(serialNumber.charAt(i), 10)
        sum += i % 2 === 0 ? 3 * digit : digit
      }
      return sum % 10 === 0
    },
    inputSerialNumber() {
      this.validationError = null;
    },
    focusOnSerialNumberInput() {
      this.$refs.serial.$el.focus()
    },
    focusOnCode1Input() {
      this.$refs.code1.$el.focus()
    },
    focusOnCode2Input() {
      this.$refs.code2.$el.focus()
    },
    isButtonDisabled() {
      switch(this.step) {
        case 2:
          return !this.isInputValid(this.serial, this.step)
        case 3:
          return !this.isInputValid(this.code1, this.step)
        case 4:
          return !this.isInputValid(this.code2, this.step)
        default:
          return false
      }
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  }
}
</script>
